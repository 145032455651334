import "./video.scss";
(($) => {
    function initLightgallery(){
        $(".lightgallery")
            .lightGallery({
                download: false,
                selector: "a",
            })
            .on("onBeforeSlide.lg", function () {
                $(".lg-backdrop").css("opacity", "0.8");
                $(".lg-sub-html").css("opacity", "0");
            });
    }

    //CUSTOM EVENTS FOR BACK AND FRONT
    if($('body').hasClass('wp-admin')){
        $(document).on("initLightgallery", initLightgallery);
    }else{
        $(document).ready(initLightgallery);
    }
})(jQuery);
